//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {queryAirline} from '@/apis/tool.js'
export default {
	data: ()=> ({
		query: '',
		dataSource: [],
		inRNBox: false,
		navigationBarBackgroundColor: '#437AFF',
	}),
	created() {
		this.inRNBox = window.ReactNativeWebView || !!this.$route.query.inRNBox || false
		if(this.inRNBox) {
			this.navigationBarBackgroundColor = '#' + (this.$route.query.navigationBarBackgroundColor || '154B79')
		}
	},
	mounted() {
		this._getList({
			code: '',
			cn: '中国'
		})
	},
	methods: {
		async _getList(params) {
			let {data = []} = await queryAirline(params)
			this.dataSource = data || []
		},
		async onSearch() {
			this.query = this.query.toLocaleUpperCase()
			let params = {
					code: '',
					cn: ''
				}
			let isCodeSearch = /[A-Za-z0-9]/g.test(this.query)
			if(isCodeSearch) {
				if(this.query.length != 2) {
					this.$toast('航司代码为2个字符')
					return
				}
				params.code = this.query
			} else {
				params.cn = this.query
			}
			await this._getList(params)
		}
	}
}
